import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("filter-drawer-composer", [_c("operator-filter", { key: "NextAuditDate", attrs: { "label": "Audit Due Date", "type": "date" } }), _c("operator-filter", { key: "AuditFrequency", attrs: { "label": "Audit Frequency", "type": "number" } }), _c("select-filter", { key: "CanSize", attrs: { "label": "Container Size", "lazy": "", "placeholder": "Select a Container size", "reference": "sterilization.can-sizes", "search-by": "Name", "source": "name", "source-label": "name" } }), _c("select-filter", { key: "Steriliser", attrs: { "label": "Steriliser", "lazy": "", "placeholder": "Select a steriliser", "reference": "sterilization.sterilisers", "search-by": "Description", "source": "description", "source-label": "description" } }), _c("operator-filter", { key: "ItemCode", attrs: { "label": "Item Code", "auto-complete-input": _vm.autoCompleteInput, "type": "queryString" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "FilterAuditGroupsDrawer",
  data() {
    const apiUrl = "#{VUE_APP_API_URL}#";
    const commonItemUrl = `${apiUrl}/sterilization/common/item-masters?fe=(ItemCode contains \` \`)&isBpcsOnly=true`;
    return {
      commonItemUrl,
      autoCompleteInput: {
        reference: "sterilization.common.item-masters",
        customQuery: `isBpcsOnly=true`,
        placeholder: "Start filtering with 'F'",
        deleteFilter: "IsInactive",
        onlyCustomQuery: true,
        source: "itemCode",
        searchBy: "ItemCode"
      },
      isLoading: true
    };
  },
  async created() {
    const { data } = await this.axios.get(this.commonItemUrl);
    this.autoCompleteInput.dataSource = data;
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterAuditGroupsDrawer = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "filter-audit-groups" }, [_c("resource", { attrs: { "name": "sterilization.can-sizes", "resource-id-name": "name", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.sterilisers", "resource-id-name": "description", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.item-masters", "api-url": _vm.apiUrl, "resource-id-name": "itemCode" } }), _c("resource", { attrs: { "name": "sterilization.audit-groups", "api-url": _vm.apiUrl, "redirect-route": "/sterilization-audits/audit-groups" } }, [_c("filter-audit-groups-drawer")], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  name: "FilterAuditGroups",
  components: {
    FilterAuditGroupsDrawer
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#"
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
